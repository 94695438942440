import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableContainer,
  Divider,
  Text,
} from '@chakra-ui/react';
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";

function PrintTransactionList() {
  const [transactionData, setTransactionData] = useState([]);
  const [error, setError] = useState(null);
  const componentRef = useRef(); // Ref for the printable component
  const [showButtons, setShowButtons] = useState(true);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setShowButtons(false),
    onAfterPrint: () => setShowButtons(true),
  });

  const location = useLocation();

  useEffect(() => {
    const { projectName, blockName, plotName } = location.state || {};

    const formData = new FormData();
    formData.append("projectName", projectName || "");
    formData.append("blockName", blockName || "");
    formData.append("plotName", plotName || "");

    const fetchData = async () => {
      try {
        const response = await fetch("https://lkgexcel.com/backend_lms/printTransations.php", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setTransactionData(data);
        } else {
          setTransactionData([]);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [location.state]);

  return (
    <div>
      <Divider w={"50%"} bg={"#121212"} />
      <Box mx={"50px"} mt={"20px"}>
        <Box ref={componentRef}>
          {transactionData.length > 0 ? (
            <TableContainer width="70%" mx="auto">
              <Table size={"sm"}>
                <Thead>
                  <Tr bg={"#121212"}>
                    <Th color={"white"}>SrNo.</Th>
                    <Th color={"white"}>Date</Th>
                    <Th color={"white"}>Type</Th>
                    <Th color={"white"}>Amount</Th>
                    <Th color={"white"}>Bank Mode</Th>
                    <Th color={"white"}>Chq/Ref No</Th>
                    <Th color={"white"}>Bank Name</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transactionData.map((res, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{res.date ? new Date(res.date).toLocaleDateString('en-GB') : 'N/A'}</Td>
                      <Td>{res.paymentType}</Td>
                      <Td>{res.amount}</Td>
                      <Td>{res.bankMode}</Td>
                      <Td>{res.cheqNo || "N/A"}</Td>
                      <Td>{res.bankName}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Text>No transactions found.</Text>
          )}
        </Box>
      </Box>
      <Box mx={"250px"} mt={"20px"}>
        {showButtons && (
          <Button onClick={handlePrint}>
            Print
          </Button>
        )}
      </Box>
      {error && <Text color="red">{error}</Text>}
    </div>
  );
}

export default PrintTransactionList;
